import * as React from "react"
import { graphql } from "gatsby"
import Episode from "../components/episode"

import Layout from "../components/layout"
import { Seo } from "../components/seo"

const EpisodesPage = ({data}) => {
  return (
    <Layout>
      <h1 style={{"paddingBottom": `var(--space-3)`}}>에피소드</h1>
      {
        data.allFile.nodes.map((node) => (
          <Episode key={node.id} node={node}></Episode>
        )) 
      }
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(
      filter: {sourceInstanceName: {eq: "episodes"}, childMdx: {id: {ne: null}}}
      sort: {fields: [childrenMdx___frontmatter___published_date, childrenMdx___frontmatter___uid], order: [DESC, DESC]}
      limit: 1000
    ) {
      nodes {
        id
        childMdx {
          frontmatter {
            title
            uid
            recording_date
            published_date
            duration
            thumbnail {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default EpisodesPage

export const Head = () => (
  <Seo pathname="/episodes" />
)
